$(function() {

	var scotchPanel = $('#slide-menu').scotchPanel({
		containerSelector: 'body',
		direction: 'left',
		duration: 300,
		transition: 'ease',
		distanceX: '70%',
		forceMinHeight: true,
		minHeight: '2500px',
		enableEscapeKey: true
	}).show();  // show to avoid flash of content

	$('.toggle-slide').click(function() {
		scotchPanel.css('overflow', 'scroll');
		scotchPanel.toggle();
		
		return false;
	});

	$('.overlay').click(function() {
		// CLOSE ONLY
		scotchPanel.close();
	});

	// heading links
	$('.docs-wrapper').find('a[name]').each(function() {
		var anchor = $('<a href="#' + this.name + '">');
		$(this).parent().next('h2').wrapInner(anchor);
	})

	// The Bar Chart
	var values = [];

	$('ul.chart').find('li .value').each(function() {
		var self = $(this);
		values.push(self.text());
	});

	var max = Math.max.apply(Math, values);

	$('ul.chart').find('li').each(function() {
		var self = $(this),
			value = self.find('.value').text(),
			value_label = self.find('.value'),
			fill = $(this).find('.bar .fill');

		percent = (value / max);
		
		fill.css({
			'height': percent * 100 + '%',
			'opacity': percent
		});

		value_label.css('bottom', (percent * 80) + 15 + '%');
	})
});